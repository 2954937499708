import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import Profile from "../Assets/Images/New_images/profile-picture.png";
import squre from "../Assets/Images/New_images/minus-square.png";
import Image from "react-bootstrap/Image";
import emptyimg from "../Assets/Images/New_images/empty_image.png";
import Button from "react-bootstrap/Button";
import {
  Autobrightness,
  Call,
  Sms,
  House,
  Buildings,
  ArrowLeft2,
  ArrowRight2,
} from "iconsax-react";
import Edit from "../Assets/Images/Edit-Linear-32px.png";
import Delete from "../Assets/Images/Trash-Linear-32px.png";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import addcircle from "../Assets/Images/New_images/add-circle.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { FormControl } from "react-bootstrap";
import Calendars from "../Assets/Images/New_images/calendar.png";
import Form from "react-bootstrap/Form";
import { Room } from "@material-ui/icons";
import { MdError } from "react-icons/md";
import { setISODay } from "date-fns";

function EBRoomReading(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [showDots, setShowDots] = useState("");
  const [activeRow, setActiveRow] = useState(null);
  const [ebEditShow, setebEditShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedHostel, setSelectedHostel] = useState("");
  const [Floor, setFloor] = useState("");
  const [Rooms, setRooms] = useState("");
  const [reading, setReading] = useState("");
  const [readingError, setReadingError] = useState("");
  const [formError, setFormError] = useState("");
  const [id,setId]=useState("")
  const [roomId,setRoomId]=useState("")
  const [hostelId,setHostelId] =useState("")
  const [floorError, setfloorError] = useState("");
  const [roomError, setRoomError] = useState("");
  const [unitAmount, setUnitAmount] = useState("");
  const [hostelIdError, setHostelIdError] = useState("");
  const [ebErrorunit, setEbErrorunit] = useState("");
  const [deleteId, setDeleteId] = useState("");

  const handleShowDots = (eb_Id) => {
    if (activeRow === eb_Id) {
      setActiveRow(null);
    } else {
      setActiveRow(eb_Id);
    }
  };
  const handleHostelChange = (e) => {
    setHostelId(e.target.value);
    setFloor("");
    setRooms("");
    setHostelIdError("");
    setEbErrorunit("");
    setReading("")
    setRoomId("")
    setFormError('')
    
  };

  const handleReadingChange = (e) => {
    setReading(e.target.value);
    setReadingError('')
    setFormError('')
    setEbErrorunit("");
    dispatch({ type: "CLEAR_ERROR_EDIT_ELECTRICITY"}); 
  };
  useEffect(() => {
    const FilterEbAmount = state.Settings.EBBillingUnitlist.eb_settings?.filter(
      (item) => item.hostel_id == hostelId
    );
    console.log("FilteredEBAmount:", FilterEbAmount);
    setUnitAmount(FilterEbAmount);
    if (Array.isArray(FilterEbAmount) && FilterEbAmount.length > 0) {
      console.log("unitAmount..123?", FilterEbAmount[0]?.amount);
      setUnitAmount(FilterEbAmount[0]?.amount);
    } else {
      console.log("unitAmount is not a valid array or is empty.");
    }
  }, [state.Settings.EBBillingUnitlist.eb_settings, hostelId]);

  
  useEffect(() => {
    setEbErrorunit(state?.PgList?.ebEditError);
   
  }, [state?.PgList?.ebEditError]);
  useEffect(() => {
    if (hostelId && Floor) {
      dispatch({
        type: "ROOMDETAILS",
        payload: { hostel_Id: hostelId, floor_Id: Floor },
      });
    }
  }, [Floor]);

  useEffect(() => {
    dispatch({
      type: "HOSTELDETAILLIST",
      payload: { hostel_Id: hostelId },
    });
  }, [hostelId]);
  const handleRoom = (e) => {
    setRooms(e.target.value);
    setRoomError("");
    setFormError("");
    setEbErrorunit("");
  };
  const handleFloor = (e) => {
    setFloor(e.target.value);
    setRooms("");
    setfloorError("");
    setFormError("");
    setRoomId("")
    setEbErrorunit("");
  };
  const handleClose = () => {
    setebEditShow(false);
    setFormError("");
    setEbErrorunit("");
  };
  const handleDateChange = (date) => {
    setDateError('');  
    setEbErrorunit(''); 
    setSelectedDate(date);
    dispatch({ type: "CLEAR_ERROR_EDIT_ELECTRICITY"}); 
  };

  const handleCloseDelete = () => {
    setDeleteShow(false);
  };

  const handleDeleteShow = (item) => {
    console.log("itemhhjhj",item)
    setDeleteShow(true);
    setDeleteId(item.eb_Id)
  };
  const popupRef = useRef(null);

  console.log("state", state);
  useEffect(() => {
    dispatch({ type: "EBLIST" });
    dispatch({ type: "EBSTARTMETERLIST" });
  }, []);
  const [initialStateAssign, setInitialStateAssign] = useState({
    selectedHostel: "",
    Floor: "",
    Rooms: "",
    reading: "",
    selectedDate: "", 
  });
  const handleEditRoomReading = (item) => {
    console.log("item123", item);
    setUnitAmount('')
    setebEditShow(true);
    setSelectedHostel(item.hostel_Id);
    setFloor(item.floor_id);
    setRooms(item.room_id);

    console.log(Rooms,"Rooms----------------------");
    
    setReading(item.reading);
    const formattedJoiningDate = item.date ? new Date(item.date) : null;
    console.log("itemEdit...///", formattedJoiningDate);
    setSelectedDate(formattedJoiningDate);
    setId(item.eb_Id)
    setRoomId(item.Room_Id) 
    setHostelId(item.hostel_Id)



    setInitialStateAssign({
      selectedHostel: item.hostel_Id || "",
      Floor: item.floor_id || "",
      Rooms: item.room_id || "",
      reading: item.reading || "",
      selectedDate: formattedJoiningDate || "",
    });
  };


 
  
  const validateAssignField = (value, fieldName) => {
    const isValueEmpty =
      (typeof value === "string" && value.trim() === "") ||
      value === undefined ||
      value === null ||
      value === "0";
    if (isValueEmpty) {
      switch (fieldName) {
        case "reading":
          setReadingError("reading is required");
          break;
          case "Hostel ID":
            setHostelIdError("Hostel is required");
            break;
          case "Floor":
            setfloorError("Floor is required");
            break;
          case "Rooms":
            setRoomError("Rooms is required");
            break;
        case "selectedDate":
          setDateError("Date is required");
          break;

        default:
          break;
      }
      return false;
    }

    // Clear the error if value is valid
    switch (fieldName) {
      case "reading":
        setReadingError("");
        break;
        case "Hostel ID":
          setHostelIdError("");
          break;
        case "Floor":
          setfloorError("");
          break;
        case "Rooms":
          setRoomError("");
          break;
      case "selectedDate":
        setDateError("");
        break;
      default:
        break;
    }

    return true;
  };
  const handleSaveChanges = () => {
    const isreadingValid = validateAssignField(reading, "reading");
    const isDatevalid = validateAssignField(selectedDate, "selectedDate");
    const isHostelValid = validateAssignField(selectedHostel,"Hostel ID");
    const isFloorValid = validateAssignField(Floor, "Floor");
    const isRoomValid = validateAssignField(Rooms, "Rooms");



    if (selectedHostel === "Select PG" || !isHostelValid) {
      setHostelIdError("Please select a valid Hostel");
      return;
    } else {
      setHostelIdError("");
    }

    if (Floor === "Select Floor" || !isFloorValid) {
      setfloorError("Please select a valid Floor");
      return;
    } else {
      setfloorError("");
    }

    // Validate Room field
    if (Rooms === "Select Room" || !isRoomValid) {
      setRoomError("Please select a valid Room");
      return;
    } else {
      setRoomError("");
    }
    // if (!isreadingValid || !isDatevalid) {
    //   return;
    // }
    if (
      !isHostelValid ||
      !isreadingValid ||
      (!isFloorValid && !isRoomValid && !isDatevalid)
    ) {
      return;
    }

    const isValidDate = (date) => {
      return !isNaN(Date.parse(date));
    };
    const isChangedBed =
    (String(Floor).toLowerCase() !== String(initialStateAssign.Floor).toLowerCase()) ||
      (String(Rooms).toLowerCase() !== String(initialStateAssign.Rooms).toLowerCase()) ||
      (String(hostelId).toLowerCase() !== String(initialStateAssign.selectedHostel).toLowerCase()) ||
    (isValidDate(selectedDate) && isValidDate(initialStateAssign.selectedDate)
    ? new Date(selectedDate).toISOString().split("T")[0] !==
      new Date(initialStateAssign.selectedDate).toISOString().split("T")[0]
    : selectedDate !== initialStateAssign.selectedDate)  ||
    String(reading) !== String(initialStateAssign.reading) 

    console.log("Comparison Results:", {
      selectedHostelComparison: selectedHostel !== initialStateAssign.selectedHostel,
      FloorComparison: Floor !== initialStateAssign.Floor,
      RoomsComparison: Rooms !== initialStateAssign.Rooms,
      selectedDateComparison: selectedDate !== initialStateAssign.selectedDate,
      readingComparison: reading !== initialStateAssign.reading,
    });

    if (!isChangedBed) {
      setFormError("No changes detected.");
      return;
    } else {
      setFormError("");
    }

    let formattedDate = null;
try {
  let date = new Date(selectedDate);
  date.setDate(date.getDate() + 1); // Add 1 day
  formattedDate = date.toISOString().split("T")[0];
} catch (error) {
  setDateError("Date is required.");
  console.error(error);
  return;
}
    dispatch({
      type: "EDITELECTRICITY",
      payload: {
        hostel_id: hostelId,
        floor_id: Floor,
        room_id: Rooms, 
        reading: reading,
        date : formattedDate,
        id:id
      },
    });

  };

  const electricityrowsPerPage = 5;
  const [electricitycurrentPage, setelectricitycurrentPage] = useState(1);
  const [electricityFilterddata, setelectricityFilterddata] = useState([]);
  const indexOfLastRowelectricity =
    electricitycurrentPage * electricityrowsPerPage;
  const indexOfFirstRowelectricity =
    indexOfLastRowelectricity - electricityrowsPerPage;
  const currentRowelectricity = electricityFilterddata?.slice(
    indexOfFirstRowelectricity,
    indexOfLastRowelectricity
  );

  const handleElectricityPageChange = (InvoicepageNumber) => {
    setelectricitycurrentPage(InvoicepageNumber);
  };

  const totalPagesinvoice = Math.ceil(
    electricityFilterddata?.length / electricityrowsPerPage
  );

  const renderPageNumberselectricity = () => {
    const pageNumberselectricity = [];
    let startPageelectricity = electricitycurrentPage - 1;
    let endPageelectricity = electricitycurrentPage + 1;

    if (electricitycurrentPage === 1) {
      startPageelectricity = 1;
      endPageelectricity = 3;
    }

    if (electricitycurrentPage === totalPagesinvoice) {
      startPageelectricity = totalPagesinvoice - 2;
      endPageelectricity = totalPagesinvoice;
    }

    if (electricitycurrentPage === 2) {
      startPageelectricity = 1;
      endPageelectricity = 3;
    }

    if (electricitycurrentPage === totalPagesinvoice - 1) {
      startPageelectricity = totalPagesinvoice - 2;
      endPageelectricity = totalPagesinvoice;
    }

    for (let i = startPageelectricity; i <= endPageelectricity; i++) {
      if (i > 0 && i <= totalPagesinvoice) {
        pageNumberselectricity.push(
          <li key={i} style={{ margin: "0 5px" }}>
            <button
              style={{
                padding: "5px 10px",
                textDecoration: "none",
                color: i === electricitycurrentPage ? "#007bff" : "#000000",
                cursor: "pointer",
                borderRadius: "5px",
                display: "inline-block",
                minWidth: "30px",
                textAlign: "center",
                backgroundColor:
                  i === electricitycurrentPage ? "transparent" : "transparent",
                border:
                  i === electricitycurrentPage ? "1px solid #ddd" : "none",
              }}
              onClick={() => handleElectricityPageChange(i)}
            >
              {i}
            </button>
          </li>
        );
      }
    }

    return pageNumberselectricity;
  };

  useEffect(() => {
    setelectricityFilterddata(state.PgList?.EB_startmeterlist);
  }, [state.PgList?.EB_startmeterlist]);


  const handleDeleteReading =()=>{
    dispatch({
      type: "DELETEECTRICITY",
      payload: {
        id:deleteId
      },
    });

  }

  useEffect(()=>{
    if(state.PgList.statusCodeForDeleteElectricity === 200){
      handleCloseDelete()
      dispatch({ type: "EBSTARTMETERLIST"});
      dispatch({ type: "CUSTOMEREBLIST"});
    
      setTimeout(() => {
        dispatch({ type: "CLEAR_DELETE_ELECTRICITY" });
      }, 200);
    
    }
    },[state.PgList.statusCodeForDeleteElectricity])




  const customDateInput = (props) => {
    return (
      <div
        className="date-input-container w-100"
        onClick={props.onClick}
        style={{ position: "relative" }}
      >
        <FormControl
          type="text"
          className="date_input"
          value={props.value || "DD/MM/YYYY"}
          readOnly
          // disabled={edit}
          style={{
            border: "1px solid #D9D9D9",
            borderRadius: 8,
            padding: 9,
            fontSize: 14,
            fontFamily: "Gilroy",
            fontWeight: props.value ? 600 : 500,
            width: "100%",
            height: 50,
            boxSizing: "border-box",
            boxShadow: "none",
          }}
        />
        <img
          src={Calendars}
          style={{
            height: 24,
            width: 24,
            marginLeft: 10,
            cursor: "pointer",
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
          }}
          alt="Calendar"
          onClick={props.onClick}
        />
      </div>
    );
  };

useEffect(()=>{
if(state.PgList.statusCodeForEditElectricity === 200){
  handleClose()
  dispatch({ type: "EBSTARTMETERLIST"});
  dispatch({ type: "CUSTOMEREBLIST"});

  setTimeout(() => {
    dispatch({ type: "CLEAR_EDIT_ELECTRICITY" });
  }, 200);

}
},[state.PgList.statusCodeForEditElectricity])

  return (
    <>
      <div>
        {currentRowelectricity.length > 0 && (
          <Table
            responsive="md"
            className="Table_Design"
            style={{
              height: "auto",
              overflow: "visible",
              tableLayout: "auto",
              borderRadius: "24px",
              border: "1px solid #DCDCDC",
            }}
          >
            <thead
              style={{
                color: "gray",
                fontSize: "11px",
                backgroundColor: "#E7F1FF",
              }}
            >
              <tr style={{ height: "30px" }}>
                <th
                  style={{
                    textAlign: "center",
                    fontFamily: "Gilroy",
                    color: "rgba(34, 34, 34, 1)",
                    fontSize: 14,
                    fontWeight: 600,
                    borderTopLeftRadius: 24,
                  }}
                >
                  <img src={squre} height={20} width={20} />
                </th>
                <th
                  style={{
                    color: "#939393",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Gilroy",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Paying Guest
                </th>
                <th
                  style={{
                    color: "#939393",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Gilroy",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Floor
                </th>
                <th
                  style={{
                    color: "#939393",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Gilroy",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Room no
                </th>
                {/* <th
                  style={{
                    color: "#939393",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Gilroy",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Previous
                </th> */}
                <th
                  style={{
                    color: "#939393",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Gilroy",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Reading
                </th>
                <th
                  style={{
                    color: "#939393",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Gilroy",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Dated
                </th>
                <th
                  style={{
                    color: "#939393",
                    fontWeight: 500,
                    fontSize: "14px",
                    fontFamily: "Gilroy",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  Units
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontFamily: "Gilroy",
                    color: "#939393",
                    fontSize: 14,
                    fontWeight: 600,
                    // borderTopRightRadius: 24
                  }}
                >
                  Amount
                </th>
                <th
                  style={{
                    textAlign: "center",
                    fontFamily: "Gilroy",
                    color: "rgba(34, 34, 34, 1)",
                    fontSize: 14,
                    fontWeight: 600,
                    borderTopRightRadius: 24,
                  }}
                >
                  {" "}
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "12px" }}>
              {currentRowelectricity &&
                currentRowelectricity.map((v) => {
                  const imageUrl = v.profile || Profile;
                  // let Dated = new Date(v.date);
                  // let Dated = v.date ? new Date(v.date) : new Date(v.initial_date); 
                  // let day = Dated.getDate();
                  // let month = Dated.getMonth() + 1;
                  // let year = Dated.getFullYear();
                  // let formattedDate = `${day}/${month}/${year}`;

                  let formattedDate;

// Check if v.date exists and is not "00-00-00"
if (v.date && v.date != '0000-00-00') {
    let Dated = new Date(v.date);
    let day = Dated.getDate();
    let month = Dated.getMonth() + 1;
    let year = Dated.getFullYear();
    formattedDate = `${day}/${month}/${year}`;
} else {
    // Use a default initial date if v.date is empty or "00-00-00"
    let initialDate = new Date(v.initial_date); // Set your default initial date here
    let day = initialDate.getDate();
    let month = initialDate.getMonth() + 1;
    let year = initialDate.getFullYear();
    formattedDate = `${day}/${month}/${year}`;
}

console.log('Formatted Date:', formattedDate);


                  return (
                    <tr key={v.id}>
                      <td
                        style={{
                          padding: "10px",
                          border: "none",
                          textAlign: "center",
                          verticalAlign: "middle", // Center vertically
                        }}
                      >
                        <img src={squre} height={20} width={20} />
                      </td>
                      <td
                        style={{
                          border: "none",
                          padding: "10px",
                          textAlign: "center",
                          verticalAlign: "middle", // Center vertically
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Image
                            src={imageUrl}
                            alt={v.hoatel_Name || "Default Profile"}
                            roundedCircle
                            style={{
                              height: "40px",
                              width: "40px",
                              marginRight: "10px",
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = Profile;
                            }}
                          />
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              fontFamily: "Gilroy",
                            }}
                          >
                            {v.hoatel_Name}
                          </span>
                        </div>
                      </td>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Gilroy",
                          textAlign: "center",
                          verticalAlign: "middle",
                          borderBottom: "none",
                        }}
                      >
                        {v.floor_name}
                      </td>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Gilroy",
                          textAlign: "center",
                          verticalAlign: "middle",
                          borderBottom: "none",
                        }}
                      >
                        {v.Room_Id}
                      </td>
                      {/* <td
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Gilroy",
                          textAlign: "center",
                          verticalAlign: "middle",
                          borderBottom: "none",
                        }}
                      >
                        {v.start_Meter_Reading}
                      </td> */}
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Gilroy",
                          textAlign: "center",
                          verticalAlign: "middle",
                          borderBottom: "none",
                        }}
                      >
                        {v.reading}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle", // Center vertically
                          borderBottom: "none",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: "#EBEBEB",
                            paddingTop: "5px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            paddingBottom: "5px",
                            borderRadius: "60px",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Gilroy",
                          }}
                        >
                          {formattedDate}
                        </span>
                      </td>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Gilroy",
                          textAlign: "center",
                          verticalAlign: "middle", // Center vertically
                          borderBottom: "none",
                        }}
                      >
                        {v.total_reading}
                      </td>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Gilroy",
                          textAlign: "center",
                          verticalAlign: "middle",
                          borderBottom: "none",
                        }}
                      >
                        {v.total_amount}
                      </td>
                      <td style={{ paddingTop: 12, border: "none" }}>
                        {/* <MoreCircle  variant="Outline"  size="40" color="#dcdcdc" style={{transform:"rotate(90deg)"}}/>  */}

                        <div
                          style={{
                            cursor: "pointer",
                            height: 40,
                            width: 40,
                            borderRadius: 100,
                            border: "1px solid #EFEFEF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            zIndex: 1000,
                          }}
                          onClick={() => handleShowDots(v.eb_Id)}
                        >
                          <PiDotsThreeOutlineVerticalFill
                            style={{ height: 20, width: 20 }}
                          />
                          {activeRow === v.eb_Id && (
                            <>
                              <div
                                ref={popupRef}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#fff",
                                  position: "absolute",
                                  right: 50,
                                  top: 20,
                                  width: 163,
                                  height: "auto",
                                  border: "1px solid #EBEBEB",
                                  borderRadius: 10,
                                  display: "flex",
                                  justifyContent: "start",
                                  padding: 10,
                                  alignItems: "center",
                                  zIndex: showDots ? 1000 : "auto",
                                }}
                              >
                                <div
                                  style={{ backgroundColor: "#fff" }}
                                  className=""
                                >
                                  <div
                                    className="mb-3 d-flex justify-content-start align-items-center gap-2"
                                    style={{ backgroundColor: "#fff" }}
                                    onClick={() => handleEditRoomReading(v)}
                                  >
                                    <img
                                      src={Edit}
                                      style={{ height: 16, width: 16 }}
                                    />{" "}
                                    <label
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        fontFamily: "Gilroy,sans-serif",
                                        color: "#222222",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Edit
                                    </label>
                                  </div>

                                  {/* <div className='mb-3 d-flex justify-content-start align-items-center gap-2'
                                                onClick={() => { handleShowform(props) }}
                                                style={{ backgroundColor: "#fff" }}
                                            >
                                                <img src={Assign} style={{ height: 16, width: 16 }} /> <label style={{ fontSize: 14, fontWeight: 500, fontFamily: "Gilroy,sans-serif", color: "#222222", cursor: 'pointer' }} >Record Payment</label>

                                            </div> */}

                                   <div
                                    className="mb-2 d-flex justify-content-start align-items-center gap-2"
                                    style={{ backgroundColor: "#fff" }}
                                    onClick={() => handleDeleteShow(v)}
                                  >
                                    <img
                                      src={Delete}
                                      style={{ height: 16, width: 16 }}
                                    />{" "}
                                    <label
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        fontFamily: "Gilroy,sans-serif",
                                        color: "#FF0000",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Delete
                                    </label>
                                  </div> 
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        {/* <img src={dottt} style={{ height: 40, width: 40 }} /> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}

        {state.PgList?.EB_startmeterlist?.length === 0 && (
          <div>
            <div style={{ textAlign: "center" }}>
              <img src={emptyimg} width={240} height={240} alt="emptystate" />
            </div>
            <div
              className="pb-1"
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontFamily: "Gilroy",
                fontSize: 20,
                color: "rgba(75, 75, 75, 1)",
              }}
            >
              No room readings{" "}
            </div>
            <div
              className="pb-1"
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontFamily: "Gilroy",
                fontSize: 16,
                color: "rgba(75, 75, 75, 1)",
              }}
            >
              There are no room readings available.{" "}
            </div>

            <div style={{ textAlign: "center" }}>
              <Button
                onClick={props.handleAddEbDetails}
                style={{
                  fontSize: 16,
                  backgroundColor: "#1E45E1",
                  color: "white",
                  height: 59,
                  fontWeight: 600,
                  borderRadius: 12,
                  width: 185,
                  padding: "18px, 20px, 18px, 20px",
                  fontFamily: "Gilroy",
                }}
              >
                + Add Reading
              </Button>
            </div>
          </div>
        )}
      </div>

      {currentRowelectricity.length > 0 && (
        <nav>
          <ul
            style={{
              display: "flex",
              alignItems: "center",
              listStyleType: "none",
              padding: 0,
              justifyContent: "end",
            }}
          >
            <li style={{ margin: "0 5px" }}>
              <button
                style={{
                  padding: "5px 10px",
                  textDecoration: "none",
                  color: electricitycurrentPage === 1 ? "#ccc" : "#007bff",
                  cursor:
                    electricitycurrentPage === 1 ? "not-allowed" : "pointer",
                  borderRadius: "5px",
                  display: "inline-block",
                  minWidth: "30px",
                  textAlign: "center",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={() =>
                  handleElectricityPageChange(electricitycurrentPage - 1)
                }
                disabled={electricitycurrentPage === 1}
              >
                {" "}
                <ArrowLeft2 size="16" color="#1E45E1" />
              </button>
            </li>
            {electricitycurrentPage > 3 && (
              <li style={{ margin: "0 5px" }}>
                <button
                  style={{
                    padding: "5px 10px",
                    textDecoration: "none",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "5px",
                    display: "inline-block",
                    minWidth: "30px",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => handleElectricityPageChange(1)}
                >
                  1
                </button>
              </li>
            )}
            {electricitycurrentPage > 3 && <span>...</span>}
            {renderPageNumberselectricity()}
            {electricitycurrentPage < totalPagesinvoice - 2 && <span>...</span>}
            {electricitycurrentPage < totalPagesinvoice - 2 && (
              <li style={{ margin: "0 5px" }}>
                <button
                  style={{
                    padding: "5px 10px",
                    textDecoration: "none",

                    cursor: "pointer",
                    borderRadius: "5px",
                    display: "inline-block",
                    minWidth: "30px",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => handleElectricityPageChange(totalPagesinvoice)}
                >
                  {totalPagesinvoice}
                </button>
              </li>
            )}
            <li style={{ margin: "0 5px" }}>
              <button
                style={{
                  padding: "5px 10px",
                  textDecoration: "none",
                  color:
                    electricitycurrentPage === electricitycurrentPage
                      ? "#ccc"
                      : "#007bff",
                  cursor:
                    electricitycurrentPage === electricitycurrentPage
                      ? "not-allowed"
                      : "pointer",
                  borderRadius: "5px",
                  display: "inline-block",
                  minWidth: "30px",
                  textAlign: "center",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={() =>
                  handleElectricityPageChange(electricitycurrentPage + 1)
                }
                disabled={electricitycurrentPage === totalPagesinvoice}
              >
                <ArrowRight2 size="16" color="#1E45E1" />
              </button>
            </li>
          </ul>
        </nav>
      )}

      <Modal
        show={ebEditShow}
        onHide={() => handleClose()}
        backdrop="static"
        centered
      >
        {/* <Modal.Header closeButton className="text-center">
            <Modal.Title style={{ fontSize: 18,fontFamily:"Gilroy",fontWeight:600 }} className="text-center">
              Add a Reading
            </Modal.Title>
          </Modal.Header> */}

        <Modal.Header style={{ marginBottom: "30px", position: "relative" }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: 600,
              fontFamily: "Gilroy",
            }}
          >
            Edit Reading
          </div>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={handleClose}
            style={{
              position: "absolute",
              right: "10px",
              top: "16px",
              border: "1px solid black",
              background: "transparent",
              cursor: "pointer",
              padding: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
            }}
          >
            <span
              aria-hidden="true"
              style={{
                fontSize: "30px",
                paddingBottom: "6px",
              }}
            >
              &times;
            </span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {ebErrorunit && (
                  <div style={{ color: "red" }}>
                    <MdError />
                    {ebErrorunit}
                  </div>
                )}
                <Form.Label
                  style={{
                    fontSize: 14,
                    color: "#222222",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                  }}
                >
                  Paying Guest
                  <span style={{ color: "red", fontSize: "20px" }}> * </span>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="border"
                  value={hostelId}
                  onChange={(e) => handleHostelChange(e)}
                  style={{
                    fontSize: 16,
                    color: "#4B4B4B",
                    fontFamily: "Gilroy",
                    lineHeight: "18.83px",
                    fontWeight: 500,
                    boxShadow: "none",
                    border: "1px solid #D9D9D9",
                    height: 50,
                    borderRadius: 8,
                  }}
                >
                  <option
                    style={{ fontSize: 14, fontWeight: 600 }}
                    selected
                    value=""
                  >
                    Select PG
                  </option>
                  {state.UsersList?.hostelList &&
                    state.UsersList?.hostelList.map((item) => (
                      <>
                        <option key={item.id} value={item.id}>
                          {item.Name}
                        </option>
                      </>
                    ))}
                </Form.Select>
                {hostelIdError && (
                  <div style={{ color: "red" }}>
                    <MdError />
                    {hostelIdError}
                  </div>
                )}
                {unitAmount &&
                  unitAmount?.length === 0 &&
                  selectedHostel != "" && (
                    <>
                      <label
                        className="pb-1"
                        style={{
                          fontSize: 12,
                          color: "red",
                          fontFamily: "Gilroy",
                          fontWeight: 500,
                        }}
                      >
                        {" "}
                        Please add a 'ebUnitAmount in Settings'
                      </label>
                    </>
                  )}
              </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Form.Label
                  style={{
                    fontSize: 14,
                    color: "#222222",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                  }}
                >
                  Floor{" "}
                  <span style={{ color: "red", fontSize: "20px" }}> * </span>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="border"
                  disabled={
                    unitAmount &&
                    unitAmount?.length === 0 &&
                    selectedHostel != ""
                  }
                  value={Floor}
                  onChange={(e) => handleFloor(e)}
                  style={{
                    fontSize: 16,
                    color: "#4B4B4B",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                    boxShadow: "none",
                    border: "1px solid #D9D9D9",
                    height: 50,
                    borderRadius: 8,
                  }}
                >
                  <option
                    style={{ fontSize: 14, fontWeight: 600 }}
                    selected
                    value=""
                  >
                    Select Floor
                  </option>
                  {state?.UsersList?.hosteldetailslist &&
                    state?.UsersList?.hosteldetailslist.map((item) => (
                      <>
                        <option key={item.floor_id} value={item.floor_id}>
                          {item.floor_name}
                        </option>
                      </>
                    ))}
                </Form.Select>
                {floorError && (
                  <div style={{ color: "red" }}>
                    <MdError />
                    {floorError}
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Form.Label
                  style={{
                    fontSize: 14,
                    color: "#222222",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                  }}
                >
                  Room{" "}
                  <span style={{ color: "red", fontSize: "20px" }}> * </span>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="border"
                  disabled={
                    unitAmount &&
                    unitAmount?.length === 0 &&
                    selectedHostel != ""
                  }
                  value={Rooms}
                  onChange={(e) => handleRoom(e)}
                  style={{
                    fontSize: 16,
                    color: "#4B4B4B",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                    boxShadow: "none",
                    border: "1px solid #D9D9D9",
                    height: 50,
                    borderRadius: 8,
                  }}
                >
                  <option>Select a Room</option>
                  {state.UsersList?.roomdetails &&
                    state.UsersList?.roomdetails.map((item) => (
                      <>
                        <option key={item.Room_Id} value={item.Room_Id}>
                          {item.Room_Name}
                        </option>
                      </>
                    ))}
                </Form.Select>
                {roomError && (
                  <div style={{ color: "red" }}>
                    <MdError />
                    {roomError}
                  </div>
                )}
              </div>

            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Form.Group className="mb-3">
                <Form.Label
                  style={{
                    fontSize: 14,
                    color: "#222222",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                  }}
                >
                  Reading{" "}
                  <span style={{ color: "red", fontSize: "20px" }}> * </span>
                </Form.Label>
                <FormControl
                  type="text"
                  id="form-controls"
                  placeholder="6542310"
                  value={reading}
                  onChange={(e) => handleReadingChange(e)}
                  style={{
                    fontSize: 16,
                    color: "#4B4B4B",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                    boxShadow: "none",
                    border: "1px solid #D9D9D9",
                    height: 50,
                    borderRadius: 8,
                  }}
                />
              </Form.Group>
              {readingError && (
                <div style={{ color: "red" }}>
                  <MdError />
                  {readingError}
                </div>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Form.Group className="mb-2" controlId="purchaseDate">
                <Form.Label
                  style={{
                    fontSize: 14,
                    color: "#222222",
                    fontFamily: "Gilroy",
                    fontWeight: 500,
                  }}
                >
                  Date <span style={{ color: "red", fontSize: "20px" }}>*</span>
                </Form.Label>
                <div style={{ position: "relative", width: "100%" }}>
                  <DatePicker
                    selected={selectedDate}
                   onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    minDate={null}
                    // disabled={edit}
                    customInput={customDateInput({
                      value: selectedDate
                        ? selectedDate.toLocaleDateString("en-GB")
                        : "",
                    })}
                  />
                </div>
              </Form.Group>
              {/* {dateError && (
        <div style={{ color: "red" }}>
          <MdError />
          {dateError}
        </div>
      )} */}
            </div>
          </div>
        </Modal.Body>
         {formError && (
                  <div style={{ color: "red" }}>
                    <MdError />
                    {formError}
                  </div>
                )}
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            style={{
              backgroundColor: "#1E45E1",
              fontWeight: 600,
              height: 50,
              borderRadius: 12,
              fontSize: 16,
              fontFamily: "Montserrat, sans-serif",
              marginTop: 20,
            }}
            onClick={handleSaveChanges}
            disabled={!!formError}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteShow}
        onHide={handleCloseDelete}
        centered
        backdrop="static"
        style={{
          width: 388,
          height: 250,
          marginLeft: "500px",
          marginTop: "200px",
        }}
      >
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title
            style={{
              fontSize: "18px",
              fontFamily: "Gilroy",
              textAlign: "center",
              fontWeight: 600,
              color: "#222222",
              flex: 1,
            }}
          >
            Delete RoomReading?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "Gilroy",
            color: "#646464",
            textAlign: "center",
            marginTop: "-20px",
          }}
        >
          Are you sure you want to delete this RoomReading?
        </Modal.Body>

        <Modal.Footer
          style={{
            justifyContent: "center",
            borderTop: "none",
            marginTop: "-10px",
          }}
        >
          <Button
            style={{
              width: 160,
              height: 52,
              borderRadius: 8,
              padding: "12px 20px",
              background: "#fff",
              color: "#1E45E1",
              border: "1px solid #1E45E1",
              fontWeight: 600,
              fontFamily: "Gilroy",
              fontSize: "14px",
              marginRight: 10,
            }}
            onClick={handleCloseDelete}
          >
            Cancel
          </Button>
          <Button
            style={{
              width: 160,
              height: 52,
              borderRadius: 8,
              padding: "12px 20px",
              background: "#1E45E1",
              color: "#FFFFFF",
              fontWeight: 600,
              fontFamily: "Gilroy",
              fontSize: "14px",
            }}
            onClick={handleDeleteReading}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default EBRoomReading;
