import React from "react";


function DashboardUpdates(){
    return(
        <>
       <div>DashboardUpdates</div>
        </>
    )
}
export default DashboardUpdates;